import { FC, MouseEventHandler, memo } from 'react'
import cn from 'classnames'
import { LeftSliderArrow, RightSliderArrow } from '@components/icons'
import s from './SignatureStylesSliderControl.module.css'

interface CollectionsSliderControl {
  onPrev: MouseEventHandler<HTMLButtonElement>
  onNext: MouseEventHandler<HTMLButtonElement>
  fullscreen?: boolean
}

const SignatureStylesSliderControl: FC<CollectionsSliderControl> = ({ onPrev, onNext, fullscreen }) => (
  <>
    <button
      className={cn(s.leftControl, s.control, fullscreen ? s.controlFull : '')}
      onClick={onPrev}
      aria-label="Previous Product Image"
    >
      <LeftSliderArrow />
    </button>
    <button
      className={cn(s.rightControl, s.control, fullscreen ? s.controlFull : '')}
      onClick={onNext}
      aria-label="Next Product Image"
    >
      <RightSliderArrow />
    </button>
  </>
)

export default memo(SignatureStylesSliderControl)
