import CollectionCard from '@components/common/CollectionCard'
import { Button } from '@components/ui'
import cn from 'classnames'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import Link from 'next/link'
import React, { FC, useState } from 'react'
import SignatureStylesSliderControl from './SignatureStylesSliderControl'

interface SignatureStylesSliderProps {
  primary: {
    background_colour?: string
    block_title?: string
    subtitle?: string
    link_text?: string
    link?: string
    columns: number
    padding_top?: boolean
    panel_text_style?: 'light' | 'dark'
    video?: {
      url: string | any
    }
    grid_on_desktop?: boolean
  }
  items: [] | any
  applyWrapper?: boolean
}

const SignatureStylesSlider: FC<SignatureStylesSliderProps> = ({ primary, items, applyWrapper = true }) => {
  const {
    background_colour,
    block_title,
    subtitle,
    link_text,
    link,
    columns,
    padding_top,
    panel_text_style,
    grid_on_desktop,
  } = primary || {}

  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slidesPerView: columns,
    spacing: 16,
    loop: true,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
  })
  const [currentMobileSlide, setCurrentMobileSlide] = useState(0)
  const [sliderRefMobile, sliderMobile] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    spacing: 12,
    slidesPerView: 1.2,
    slideChanged(s) {
      setCurrentMobileSlide(s.details().relativeSlide)
    },
    loop: true,
  })

  const onPrev = React.useCallback(() => slider.prev(), [slider])
  const onNext = React.useCallback(() => slider.next(), [slider])

  return (
    <div
      className={cn('pb-40 tablet:pb-80', {
        'pt-40 laptop:pt-72': padding_top,
      })}
      style={{ background: background_colour }}
    >
      <div className={cn('overflow-hidden pr-0', { wrapper: applyWrapper })}>
        {subtitle && (
          <div className={`title ${panel_text_style === 'light' ? 'text-white' : 'text-moss'} mb-8`}>{subtitle}</div>
        )}
        <div className=" block mb-32 tablet:flex justify-between tablet:mb-0">
          <h2 className={`heading-5 mb-24 tablet:mb-32 ${panel_text_style === 'light' ? 'text-white' : ''}`}>
            {block_title}
          </h2>
          {link && (
            <Link href={link || '/'} passHref legacyBehavior>
              <a className="hidden tablet:flex items-center gap-5 uppercase -mr-30">
                <Button variant="buttonLink" className="buttonLink" colorSchema={panel_text_style}>
                  {link_text}
                </Button>
              </a>
            </Link>
          )}
        </div>

        {!grid_on_desktop ? (
          <div className="h-0 tablet:h-auto sliderContainer">
            <div ref={sliderRef} className="keen-slider h-full ">
              {items &&
                items?.map((item: any, index: number) => {
                  const {
                    collection_image,
                    collection_title,
                    collection_icon,
                    collection_description,
                    tags,
                    collection_link,
                  } = item || {}
                  const newTag = tags?.split(',')?.filter((item: any) => item === 'new')
                  const limitedEditionTag = tags?.split(',').find((item: any) => item === 'limited edition')
                  const saleTag = tags?.split(',').find((item: any) => item.includes('sale'))
                  const salePercentage = saleTag?.split('-')

                  return (
                    <div className="keen-slider__slide" key={index}>
                      <CollectionCard
                        key={index}
                        index={index}
                        collectionImage={collection_image}
                        newTag={newTag}
                        limitedEditionTag={limitedEditionTag}
                        saleTag={saleTag}
                        salePercentage={salePercentage}
                        collectionIcon={collection_icon}
                        collectionTitle={collection_title}
                        collectionDescription={collection_description}
                        collectionLink={collection_link}
                        video={item.video}
                        panel_text_style={panel_text_style}
                      />
                    </div>
                  )
                })}
              {slider && (
                <div className="opacity-0 sliderControl">
                  <SignatureStylesSliderControl onPrev={onPrev} onNext={onNext} />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="hidden tablet:grid tablet:grid-cols-3 tablet:gap-12">
            {items &&
              items?.map((item: any, index: number) => {
                const {
                  collection_image,
                  collection_title,
                  collection_icon,
                  collection_description,
                  tags,
                  collection_link,
                } = item || {}

                const newTag = tags?.split(',')?.filter((item: any) => item === 'new')
                const limitedEditionTag = tags?.split(',').find((item: any) => item === 'limited edition')
                const saleTag = tags?.split(',').find((item: any) => item.includes('sale'))
                const salePercentage = saleTag?.split('-')
                return (
                  <div key={index}>
                    <CollectionCard
                      key={index}
                      index={index}
                      collectionImage={collection_image}
                      newTag={newTag}
                      limitedEditionTag={limitedEditionTag}
                      saleTag={saleTag}
                      salePercentage={salePercentage}
                      collectionIcon={collection_icon}
                      collectionTitle={collection_title}
                      collectionDescription={collection_description}
                      collectionLink={collection_link}
                      video={item.video}
                      panel_text_style={panel_text_style}
                    />
                  </div>
                )
              })}
          </div>
        )}

        <div className="block h-auto opacity-100 tablet:h-0 tablet:opacity-0 -mr-20">
          <div ref={sliderRefMobile} className="keen-slider">
            {items &&
              items?.map((item: any, index: number) => {
                const {
                  collection_image,
                  collection_title,
                  collection_icon,
                  collection_description,
                  tags,
                  collection_link,
                } = item || {}

                const newTag = tags?.split(',')?.filter((item: any) => item === 'new')
                const limitedEditionTag = tags?.split(',').find((item: any) => item === 'limited edition')
                const saleTag = tags?.split(',').find((item: any) => item.includes('sale'))
                const salePercentage = saleTag?.split('-')
                return (
                  <div
                    key={index}
                    className={cn('keen-slider__slide', {
                      //'-mr-44': index === currentMobileSlide,
                    })}
                  >
                    <CollectionCard
                      collectionImage={collection_image}
                      newTag={newTag}
                      limitedEditionTag={limitedEditionTag}
                      saleTag={saleTag}
                      salePercentage={salePercentage}
                      collectionIcon={collection_icon}
                      collectionTitle={collection_title}
                      collectionDescription={collection_description}
                      collectionLink={collection_link}
                      video={item.video}
                      mobileSize={true}
                    />
                  </div>
                )
              })}
          </div>
        </div>
        {sliderMobile && (
          <div className="flex justify-center mt-28 bg-lightGrey tablet:hidden">
            {[...Array(sliderMobile.details().size).keys()].map((idx) => {
              return (
                <div
                  key={idx}
                  className={
                    'w-11/12 h-4 rounded-5 focus:outline-none' + (currentMobileSlide === idx ? ' bg-black' : '')
                  }
                />
              )
            })}
          </div>
        )}

        {link && (
          <Link href={link || '/'} passHref legacyBehavior>
            <a className="flex items-center gap-5 uppercase tablet:hidden mt-30 -ml-14">
              <Button variant="buttonLink" className="buttonLink">
                {link_text}
              </Button>
            </a>
          </Link>
        )}
      </div>

      <style jsx>
        {`
          .sliderControl {
            transition: all 0.5s ease;
          }
          .sliderContainer:hover .sliderControl {
            opacity: 1;
          }
        `}
      </style>
    </div>
  )
}

export default SignatureStylesSlider
